import React, { useState } from "react";

// *********************Need to refactor**********************
// Separate inputs into further components?

function DayInput(props) {
  const [state, setState] = useState({
    inHours: "",
    inMinutes: "",
    outHours: "",
    outMinutes: "",
    breakHours: "",
    breakMinutes: "",
  });

  // in hours set to AM by default
  const [inPm, setInPm] = useState(false);
  // out hours set to PM by default
  const [outPm, setOutPm] = useState(false);

  // state that disables inputs when added
  const [addPressed, setAddPressed] = useState(false);

  // set error message
  const [errorMessage, setErrorMessage] = useState("");

  function handleChange(e) {
    const { name, value } = e.target;

    // set regex
    let re = new RegExp("^([1-9]|[1-5][0-9]|all)$");
    let check = re.exec(value);

    // check regex
    if (check || value === "") {
      setErrorMessage("");
    } else if (!check) {
      setErrorMessage("Input Error");
    }

    setState((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  }

  // toggle state on in time AM/PM
  function handleInAmPm() {
    setInPm((inPm) => !inPm);
  }

  // toggle state on out time AM/PM
  function handleOutAmPm() {
    setOutPm((outPm) => !outPm);
  }

  // called by Add Day button
  function handleSubmit(e) {
    let ot = 0;
    let dayTotal = 0;

    // calculate day total
    if (
      (!inPm && outPm && Number(state.outHours) !== 12) ||
      (((inPm && outPm) || (!inPm && !outPm)) && Number(state.inHours) === 12)
    ) {
      dayTotal = Number(
        (
          Number(state.outHours) -
          Number(state.inHours) +
          (Number(state.outMinutes) - Number(state.inMinutes)) / 60 +
          12 -
          Number(state.breakHours) +
          Number(state.breakMinutes / 60)
        ).toFixed(2)
      );
    } else {
      dayTotal = Number(
        (
          Number(state.outHours) -
          Number(state.inHours) +
          (Number(state.outMinutes) - Number(state.inMinutes)) / 60 -
          Number(state.breakHours) +
          Number(state.breakMinutes / 60)
        ).toFixed(2)
      );
    }

    // calculate sick time
    let sick = Number(dayTotal * 0.03);

    // calculate ot
    if (dayTotal > 8) {
      ot = dayTotal - 8;
      dayTotal = 8;
    }

    // send day's totals to WeekInput function addDayHours()
    props.onSubmit(dayTotal, ot, sick);

    setAddPressed(true);
    e.preventDefault();
  }

  return (
    <div>
      <form>
        <span>{props.day}</span>
        {errorMessage}
        {addPressed ? (
          <input
            className="day-input"
            type="text"
            name="inHours"
            placeholder="in Hours"
            onChange={handleChange}
            value={state.inHours}
            disabled
          ></input>
        ) : (
          <input
            className="day-input"
            type="text"
            name="inHours"
            placeholder="in Hours"
            onChange={handleChange}
            value={state.inHours}
          ></input>
        )}
        :
        {addPressed ? (
          <input
            className="day-input"
            type="text"
            name="inMinutes"
            placeholder="in Minutes"
            onChange={handleChange}
            value={state.inMinutes}
            disabled
          ></input>
        ) : (
          <input
            className="day-input"
            type="text"
            name="inMinutes"
            placeholder="in Minutes"
            onChange={handleChange}
            value={state.inMinutes}
          ></input>
        )}
        {addPressed ? (
          <select onChange={handleInAmPm} disabled>
            <option name="inAm" value="inAm">
              AM
            </option>
            <option name="inPm" value="inPm">
              PM
            </option>
          </select>
        ) : (
          <select onChange={handleInAmPm}>
            <option name="inAm" value="inAm">
              AM
            </option>
            <option name="inPm" value="inPm">
              PM
            </option>
          </select>
        )}
        {addPressed ? (
          <input
            className="day-input"
            type="text"
            name="outHours"
            placeholder="out Hours"
            onChange={handleChange}
            value={state.outHours}
            disabled
          ></input>
        ) : (
          <input
            className="day-input"
            type="text"
            name="outHours"
            placeholder="out Hours"
            onChange={handleChange}
            value={state.outHours}
          ></input>
        )}
        :
        {addPressed ? (
          <input
            className="day-input"
            type="text"
            name="outMinutes"
            placeholder="out Minutes"
            onChange={handleChange}
            value={state.outMinutes}
            disabled
          ></input>
        ) : (
          <input
            className="day-input"
            type="text"
            name="outMinutes"
            placeholder="out Minutes"
            onChange={handleChange}
            value={state.outMinutes}
          ></input>
        )}
        {addPressed ? (
          <select onChange={handleOutAmPm} disabled>
            <option name="inAm" value="inAm">
              AM
            </option>
            <option name="inPm" value="inPm">
              PM
            </option>
          </select>
        ) : (
          <select onChange={handleOutAmPm}>
            <option name="inAm" value="inAm">
              AM
            </option>
            <option name="inPm" value="inPm">
              PM
            </option>
          </select>
        )}
        {addPressed ? (
          <input
            className="day-input"
            type="text"
            name="breakHours"
            placeholder="Break Hours"
            onChange={handleChange}
            value={state.breakHours}
            disabled
          ></input>
        ) : (
          <input
            className="day-input"
            type="text"
            name="breakHours"
            placeholder="Break Hours"
            onChange={handleChange}
            value={state.breakHours}
          ></input>
        )}
        :
        {addPressed ? (
          <input
            className="day-input"
            type="text"
            name="breakMinutes"
            placeholder="Break Minutes"
            onChange={handleChange}
            value={state.breakMinutes}
            disabled
          ></input>
        ) : (
          <input
            className="day-input"
            type="text"
            name="breakMinutes"
            placeholder="Break Minutes"
            onChange={handleChange}
            value={state.breakMinutes}
          ></input>
        )}
        <span>Break: {Number(state.breakHours) + Number(state.breakMinutes / 60)}</span>
        {/* display total only when hour fields are filled */}
        {state.inHours && state.outHours ? (
          // when in time is AM and out time is PM add 12 to out hours
          (!inPm && outPm && Number(state.outHours) !== 12) ||
          (((inPm && outPm) || (!inPm && !outPm)) &&
            Number(state.inHours) === 12) ? (
            <span className="day-total">
              Total:{" "}
              {(
                Number(state.outHours) -
                Number(state.inHours) +
                (Number(state.outMinutes) - Number(state.inMinutes)) / 60 +
                12 -
                Number(state.breakHours) +
                Number(state.breakMinutes / 60)
              ).toFixed(2)}
            </span>
          ) : (
            <span className="day-total">
              Total:{" "}
              {(
                Number(state.outHours) -
                Number(state.inHours) +
                (Number(state.outMinutes) - Number(state.inMinutes)) / 60 -
                Number(state.breakHours) +
                Number(state.breakMinutes / 60)
              ).toFixed(2)}
            </span>
          )
        ) : (
          <span className="day-total">Total: </span>
        )}
        
        <button onClick={handleSubmit}>Add Day</button>
      </form>
    </div>
  );
}

export default DayInput;
