import React, { useState, useEffect } from "react";
import InputGuess from "./InputGuess";
import Heading from "./Heading";
import Keyboard from "./Keyboard";

function Game() {
  const [answer, setAnswer] = useState(""); // variable holds answer chosen from random words api
  const [win, setWin] = useState(false);
  const [guess, setGuess] = useState([]); // variable holds guess made by inputs, passed to keyboard to grey out keys
  // const [key, setKey] = useState("");
  const [loser, setLoser] = useState(false);

  // called by InputGuess when guess is submitted
  function checkGuess(inputGuess, winner) {
    // winner is undefined if guess does not match answer in InputGuess
    setGuess((prev) => {
      // sets variable for guesses
      return [...prev, inputGuess]; // keeps previous variables
    });

    if (winner === "") {
      setWin(true);
    }
  }

  function lastGuess(_inputGuess, winner) {
    if (winner !== "") {
      setLoser(true);
    } else {
      setWin(true);
    }
  }

  function playAgain() {
    window.location.reload();
  }

  // function handleClick(key) {
  //   setKey(key);
  //   console.log(key);
  // }

  useEffect(() => {
    const options = {
      method: "GET",
    };

    fetch("https://random-word-api.herokuapp.com/word?length=5", options)
      .then((response) => response.json())
      .then(function (response) {
        const answer = response[0];
        setAnswer(answer.toUpperCase());
      })
      .catch((err) => console.error(err));
  }, []);

  return (
    <div className="App">
      {loser ? (
        <div id="loser">
          <h1>The word was</h1>
          <h1>{answer}</h1>
          <h1>You lose</h1>
          <button onClick={playAgain} className="play">
            Play Again
          </button>
        </div>
      ) : (
        <>
          {win ? (
            <div id="winner">
              {/* <div className="pyro">
                <div className="before"></div>
                <div className="after"></div>
              </div> */}
              <h1>The word is {answer}</h1>
              <h1>You Win!!!</h1>
              <button onClick={playAgain} className="play">
                Play Again
              </button>
            </div>
          ) : (
            <div className="App">
              <Heading heading="SLurdle" />
              <div className="guess-table">
                {/* <InputGuess id="1" onSubmit={checkGuess} answer={answer} inputKey={key} /> */}
                <InputGuess onSubmit={checkGuess} answer={answer} />
                <InputGuess onSubmit={checkGuess} answer={answer} />
                <InputGuess onSubmit={checkGuess} answer={answer} />
                <InputGuess onSubmit={checkGuess} answer={answer} />
                <InputGuess onSubmit={checkGuess} answer={answer} />
                <InputGuess onSubmit={lastGuess} answer={answer} />
              </div>
              {/* <Keyboard onClick={handleClick} guess={guess} /> */}
              <Keyboard guess={guess} />
            </div>
          )}
        </>
      )}
    </div>
  );
}

export default Game;
