import React, { useState } from "react";
import DayInput from "./DayInput";

function WeekInput(props) {
  const [week, setWeek] = useState({
    employee: "",
    range: "",
    weekTotal: "",
    otHours: "",
    twoWeekTotal: "",
    sickHours: "",
  });

  // boolean to show week total if "Calculate Week Total" button is clicked
  const [showTotal, setShowTotal] = useState(false);

  // array of hours, ot, and sick time in the week
  const [dayHours, setDayHours] = useState([]);
  const [ot, setOt] = useState([]);
  const [sick, setSick] = useState([]);

  function handleChange(e) {
    const { name, value } = e.target;
    setWeek((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  }

  // called by Add Week button
  function handleSubmit(e) {
    // call addWeek() in App, sending week data
    props.onAdd(week);

    e.preventDefault();
  }

  // called by DayInput to set the week's hours, ot, and sick hours
  function addDayHours(hours, overTime, sickTime) {
    setDayHours((prevDayHour) => {
      return [...prevDayHour, hours];
    });

    setOt((prevOt) => {
      return [...prevOt, overTime];
    });

    setSick((prevSick) => {
      return [...prevSick, sickTime];
    });
  }

  // called when "Calculate Week Total" button is clicked
  function addWeek(e) {
    // sum the contents of the array
    const reducer = (prevHour, currHour) => prevHour + currHour;
    const weekTotal = dayHours.reduce(reducer);
    const otHours = ot.reduce(reducer);
    const sickHours = sick.reduce(reducer).toFixed(2);

    setWeek((prev) => {
      return {
        ...prev,
        weekTotal: weekTotal,
        otHours: otHours,
        twoWeekTotal: "",
        sickHours: sickHours,
      };
    });
    setShowTotal(true);
    e.preventDefault();
  }

  return (
    <div>
      <DayInput onSubmit={addDayHours} day="Monday" />
      <DayInput onSubmit={addDayHours} day="Tuesday" />
      <DayInput onSubmit={addDayHours} day="Wednesday" />
      <DayInput onSubmit={addDayHours} day="Thursday" />
      <DayInput onSubmit={addDayHours} day="Friday" />
      <DayInput onSubmit={addDayHours} day="Saturday" />
      <DayInput onSubmit={addDayHours} day="Sunday" />
      <form>
        <input
          type="text"
          name="employee"
          placeholder="Employee Name"
          value={week.employee}
          onChange={handleChange}
          className="week-input"
        ></input>
        <input
          type="text"
          name="range"
          placeholder="Week Range"
          value={week.range}
          onChange={handleChange}
          className="week-input"
        ></input>
        {/* <button
            onClick={() => {
              props.onDelete(props.id);
            }}
          >
            DELETE
          </button> */}
        {showTotal ? (
          <h1>Week Total: {week.weekTotal}</h1>
        ) : (
          <h1>Week Total: </h1>
        )}
        <button onClick={addWeek}>Calculate Total for {week.range}</button>
        <button onClick={handleSubmit}>Add to Report</button>
      </form>
    </div>
  );
}

export default WeekInput;
