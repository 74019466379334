import React from "react";

function Heading(props) {
  return (
    <>
      <h1 className="heading">{props.heading}</h1>
      <hr />
    </>
  );
}

export default Heading;
