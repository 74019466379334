import React from "react";

function Report(props) {
  // return row data
  return (
    <tbody>
      <tr>
        <td className="td-time">{props.employee}</td>
        <td className="td-time">{props.weekTotal}</td>
        <td className="td-time">{props.otHours}</td>
        <td className="td-time">{props.twoWeekTotal}</td>
        <td className="td-time">{props.sickHours}</td>
      </tr>
    </tbody>

    //  <button
    //       onClick={() => {
    //         props.onDelete(props.id);
    //       }}
    //     >
    //       DELETE
    //     </button>
  );
}

export default Report;
