import React from "react";
import Footer from "./Footer";
import Heading from "./Heading";

function Contact() {
  return (
    <>
      <div className="App">
        <Heading heading="Contact" />
        <p>Do you have questions?</p>
        <p>I can answer them.</p>
        <p>Contact me!</p>
        <a className="email" href="mailto:stanleyleem@gmail.com">
          stanleyleem@gmail.com
        </a>
      </div>
      <Footer />
    </>
  );
}

export default Contact;
