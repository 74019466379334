import React from "react";

function Footer() {
  let year = new Date().getFullYear();
  return (
    <>
      <div className="bottom-container">
        <a className="footer-link" href="https://github.com/stanleyleem">
          <img
            className="git-icon"
            title="Git Hub"
            src="./images/GitIcon.png"
            alt="Git icon"
          />
        </a>
        <a
          className="footer-link"
          href="https://www.linkedin.com/in/stanley-leem-29b4891b6/"
        >
          <img
            className="linked-in"
            title="LinkedIn"
            src="./images/linkedIn.png"
            alt="LinkedIn"
          />
        </a>
        <p className="copyright">© {year} Stanley Leem</p>
      </div>
    </>
  );
}

export default Footer;
