import React from "react";

function PrintReport() {
  return (
    <div>
      <button>Print</button>
    </div>
  );
}

export default PrintReport;
