// import React, { useState } from "react";
import React from "react";

function Keyboard(props) {
  // const [key, setKey] = useState("");
  
  // map letters from guesses to arrays
  let resultOne = props.guess.map(a => a.letterOne);
  let resultTwo = props.guess.map(a => a.letterTwo);
  let resultThree = props.guess.map(a => a.letterThree);
  let resultFour = props.guess.map(a => a.letterFour);
  let resultFive = props.guess.map(a => a.letterFive);

  // map only unique answers to one array
  const results = [...new Set([...resultOne ,...resultTwo ,...resultThree ,...resultFour, ...resultFive])];

  // function handleClick(e) {
  //   setKey(e.target.id);

  //   console.log(key);

  //   props.onClick(key);

  //   e.preventDefault();
  //   e.stopPropagation();
  // }
  return (
    <div id="container">
      <ul id="keyboard">

        <div className="top-row">
          {results.includes("Q") ? (<li className="letter-used">q</li>) : (<li id="Q" className="letter">q</li>)}
          {results.includes("W") ? (<li className="letter-used">w</li>) : (<li id="W" className="letter">w</li>)}
          {results.includes("E") ? (<li className="letter-used">e</li>) : (<li id="E" className="letter">e</li>)}
          {results.includes("R") ? (<li className="letter-used">r</li>) : (<li id="R" className="letter">r</li>)}
          {results.includes("T") ? (<li className="letter-used">t</li>) : (<li id="T" className="letter">t</li>)}
          {results.includes("Y") ? (<li className="letter-used">y</li>) : (<li id="Y" className="letter">y</li>)}
          {results.includes("U") ? (<li className="letter-used">u</li>) : (<li id="U" className="letter">u</li>)}
          {results.includes("I") ? (<li className="letter-used">i</li>) : (<li id="I" className="letter">i</li>)}
          {results.includes("O") ? (<li className="letter-used">o</li>) : (<li id="O" className="letter">o</li>)}
          {results.includes("P") ? (<li className="letter-used">p</li>) : (<li id="P" className="letter">p</li>)}
        </div>

        <div className="middle-row">
          {results.includes("A") ? (<li className="letter-used">a</li>) : (<li id="A" className="letter">a</li>)}
          {results.includes("S") ? (<li className="letter-used">s</li>) : (<li id="S" className="letter">s</li>)}
          {results.includes("D") ? (<li className="letter-used">d</li>) : (<li id="D" className="letter">d</li>)}
          {results.includes("F") ? (<li className="letter-used">f</li>) : (<li id="F" className="letter">f</li>)}
          {results.includes("G") ? (<li className="letter-used">g</li>) : (<li id="G" className="letter">g</li>)}
          {results.includes("H") ? (<li className="letter-used">h</li>) : (<li id="H" className="letter">h</li>)}
          {results.includes("J") ? (<li className="letter-used">j</li>) : (<li id="J" className="letter">j</li>)}
          {results.includes("K") ? (<li className="letter-used">k</li>) : (<li id="K" className="letter">k</li>)}
          {results.includes("L") ? (<li className="letter-used">l</li>) : (<li id="L" className="letter">l</li>)}
        </div>

        <div className="bottom-row">
          <li className="delete">delete</li>
          {results.includes("Z") ? (<li className="letter-used">z</li>) : (<li id="Z" className="letter">z</li>)}
          {results.includes("X") ? (<li className="letter-used">x</li>) : (<li id="X" className="letter">x</li>)}
          {results.includes("C") ? (<li className="letter-used">c</li>) : (<li id="C" className="letter">c</li>)}
          {results.includes("V") ? (<li className="letter-used">v</li>) : (<li id="V" className="letter">v</li>)}
          {results.includes("B") ? (<li className="letter-used">b</li>) : (<li id="B" className="letter">b</li>)}
          {results.includes("N") ? (<li className="letter-used">n</li>) : (<li id="N" className="letter">n</li>)}
          {results.includes("M") ? (<li className="letter-used">m</li>) : (<li id="M" className="letter">m</li>)}
          <li className="delete">enter</li>
        </div>
      </ul>
    </div>
  );
}

export default Keyboard;
