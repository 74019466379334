import React from "react";

function TableHeading() {
  return (
    <tbody>
      <tr>
        <th>Employee Name</th>
        <th>Regular</th>
        <th>OT</th>
        <th>Subtotal</th>
        <th>Sick</th>
      </tr>
    </tbody>
  );
}

export default TableHeading;
