import React from "react";
import * as ReactDOMClient from "react-dom/client";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { Home, About, Game, Navigation, Contact } from "./components";
import Time from "./components/Time.jsx";

const root = ReactDOMClient.createRoot(document.getElementById("root"));

root.render(
  <Router>
    <Navigation />
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/about" element={<About />} />
      <Route path="/game" element={<Game />} />
      <Route path="/time" element={<Time />} />
      <Route path="/contact" element={<Contact />} />
    </Routes>
  </Router>
);
