import React from "react";
import { NavLink } from "react-router-dom";

function Navigation() {
  return (
    <div className="navigation">
      <nav className="navbar">
        <div>
          <NavLink className="nav-link" to="/">
            Stanley Leem
          </NavLink>
          <NavLink className="nav-link" to="/about">
            About
          </NavLink>
          <div className="dropdown">
            <button className="dropbtn">Projects</button>
            <div className="dropdown-slurdle">
              <NavLink className="nav-link" to="/game">
                SLurdle (In Development)
              </NavLink>
              <NavLink className="nav-link" to="/time">
                Time Card Calculator (In Development)
              </NavLink>
              <a className="nav-link" href="https://farmsignal.readthedocs.io/en/latest/index.html">
                Farm Signal (Senior Design Project)
              </a>
            </div>
          </div>
          <NavLink className="nav-link" to="/contact">
            Contact
          </NavLink>
        </div>
      </nav>
    </div>
  );
}

export default Navigation;
