import React, { useState } from "react";

function InputGuess(props) {
  const wholeAnswer = props.answer;

  // on screen key from game via keyboard
  // const key = props.inputKey;

  const answer = {
    letterOne: wholeAnswer[0],
    letterTwo: wholeAnswer[1],
    letterThree: wholeAnswer[2],
    letterFour: wholeAnswer[3],
    letterFive: wholeAnswer[4],
  };

  const [guess, setGuess] = useState({
    letterOne: "",
    letterTwo: "",
    letterThree: "",
    letterFour: "",
    letterFive: "",
  });

  const [madeGuess, setMadeGuess] = useState(false);
  const [winner, setWinner] = useState("");

  function handleChange(e) {
    // console.log(key);
    const { name, value } = e.target;

    const [fieldName, fieldIndex] = name.split("-");

    setGuess((prevValue) => {
      if (name === "letter-1") {
        return {
          letterOne: value.toUpperCase(),
          letterTwo: prevValue.letterTwo,
          letterThree: prevValue.letterThree,
          letterFour: prevValue.letterFour,
          letterFive: prevValue.letterFive,
        };
      } else if (name === "letter-2") {
        return {
          letterOne: prevValue.letterOne,
          letterTwo: value.toUpperCase(),
          letterThree: prevValue.letterThree,
          letterFour: prevValue.letterFour,
          letterFive: prevValue.letterFive,
        };
      } else if (name === "letter-3") {
        return {
          letterOne: prevValue.letterOne,
          letterTwo: prevValue.letterTwo,
          letterThree: value.toUpperCase(),
          letterFour: prevValue.letterFour,
          letterFive: prevValue.letterFive,
        };
      } else if (name === "letter-4") {
        return {
          letterOne: prevValue.letterOne,
          letterTwo: prevValue.letterTwo,
          letterThree: prevValue.letterThree,
          letterFour: value.toUpperCase(),
          letterFive: prevValue.letterFive,
        };
      } else if (name === "letter-5") {
        return {
          letterOne: prevValue.letterOne,
          letterTwo: prevValue.letterTwo,
          letterThree: prevValue.letterThree,
          letterFour: prevValue.letterFour,
          letterFive: value.toUpperCase(),
        };
      }
    });

    if (value.length >= 1) {
      // check if they hit the max character length
      if (parseInt(fieldIndex, 10) < 5) {
        // check if it's not the last input field
        const nextSibling = document.querySelector(
          // get the next input field
          `input[name=letter-${parseInt(fieldIndex, 10) + 1}]`
        );

        if (nextSibling !== null) {
          // if found, focus the next field
          nextSibling.focus();
        }
      }
    }
  }

  function handleSubmit(e) {
    setMadeGuess(true);

    if (JSON.stringify(guess) === JSON.stringify(answer)) {
      // compare guesses to answer
      setWinner("winner");
      console.log(winner);
      props.onSubmit(guess, winner); // send guess and winner for this row to the game for use in keyboard
    } else {
      props.onSubmit(guess); // send guess for this row to the game for use in keyboard
    }
    e.preventDefault();
    e.stopPropagation();
  }

  return (
    <div className="guess-row">
      <form onSubmit={handleSubmit}>
        <table>
          {!madeGuess ? (
            <tbody>
              <tr>
                <td>
                  <input
                    onChange={handleChange}
                    type="text"
                    name="letter-1"
                    maxLength="1"
                    className="input-box"
                    value={guess.letterOne}
                    autoComplete="off"
                  ></input>
                </td>
                <td>
                  <input
                    onChange={handleChange}
                    type="text"
                    name="letter-2"
                    maxLength="1"
                    className="input-box"
                    value={guess.letterTwo}
                    autoComplete="off"
                  ></input>
                </td>
                <td>
                  <input
                    onChange={handleChange}
                    type="text"
                    name="letter-3"
                    maxLength="1"
                    className="input-box"
                    value={guess.letterThree}
                    autoComplete="off"
                  ></input>
                </td>
                <td>
                  <input
                    onChange={handleChange}
                    type="text"
                    name="letter-4"
                    maxLength="1"
                    className="input-box"
                    value={guess.letterFour}
                    autoComplete="off"
                  ></input>
                </td>
                <td>
                  <input
                    onChange={handleChange}
                    type="text"
                    name="letter-5"
                    maxLength="1"
                    className="input-box"
                    value={guess.letterFive}
                    autoComplete="off"
                  ></input>
                </td>
              </tr>
            </tbody>
          ) : (
            <tbody>
              <tr>
                {answer.letterOne === guess.letterOne ? (
                  <td>
                    <div className="box-exact">
                      <h1 className="guess-text">{guess.letterOne}</h1>
                    </div>
                  </td>
                ) : (
                  <td>
                    {Object.values(answer).includes(guess.letterOne) ? (
                      <div className="box-close">
                        <h1 className="guess-text">{guess.letterOne}</h1>
                      </div>
                    ) : (
                      <div className="box-wrong">
                        <h1 className="guess-text">{guess.letterOne}</h1>
                      </div>
                    )}
                  </td>
                )}
                {answer.letterTwo === guess.letterTwo ? (
                  <td>
                    <div className="box-exact">
                      <h1 className="guess-text">{guess.letterTwo}</h1>
                    </div>
                  </td>
                ) : (
                  <td>
                    {Object.values(answer).includes(guess.letterTwo) ? (
                      <div className="box-close">
                        <h1 className="guess-text">{guess.letterTwo}</h1>
                      </div>
                    ) : (
                      <div className="box-wrong">
                        <h1 className="guess-text">{guess.letterTwo}</h1>
                      </div>
                    )}
                  </td>
                )}
                {answer.letterThree === guess.letterThree ? (
                  <td>
                    <div className="box-exact">
                      <h1 className="guess-text">{guess.letterThree}</h1>
                    </div>
                  </td>
                ) : (
                  <td>
                    {Object.values(answer).includes(guess.letterThree) ? (
                      <div className="box-close">
                        <h1 className="guess-text">{guess.letterThree}</h1>
                      </div>
                    ) : (
                      <div className="box-wrong">
                        <h1 className="guess-text">{guess.letterThree}</h1>
                      </div>
                    )}
                  </td>
                )}
                {answer.letterFour === guess.letterFour ? (
                  <td>
                    <div className="box-exact">
                      <h1 className="guess-text">{guess.letterFour}</h1>
                    </div>
                  </td>
                ) : (
                  <td>
                    {Object.values(answer).includes(guess.letterFour) ? (
                      <div className="box-close">
                        <h1 className="guess-text">{guess.letterFour}</h1>
                      </div>
                    ) : (
                      <div className="box-wrong">
                        <h1 className="guess-text">{guess.letterFour}</h1>
                      </div>
                    )}
                  </td>
                )}
                {answer.letterFive === guess.letterFive ? (
                  <td>
                    <div className="box-exact">
                      <h1 className="guess-text">{guess.letterFive}</h1>
                    </div>
                  </td>
                ) : (
                  <td>
                    {Object.values(answer).includes(guess.letterFive) ? (
                      <div className="box-close">
                        <h1 className="guess-text">{guess.letterFive}</h1>
                      </div>
                    ) : (
                      <div className="box-wrong">
                        <h1 className="guess-text">{guess.letterFive}</h1>
                      </div>
                    )}
                  </td>
                )}
              </tr>
            </tbody>
          )}
        </table>
        <button className="button-submit" onSubmit={handleSubmit}></button>
      </form>
    </div>
  );
}

export default InputGuess;
