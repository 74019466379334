import React, { useState } from "react";
import Heading from "./Heading";
import Report from "./Report";
import WeekInput from "./WeekInput";
import ReportHeading from "./ReportHeading";
import TableHeading from "./TableHeading";
import PrintReport from "./PrintReport";

function Time() {
    // holds array used by Report
    const [weeks, setWeeks] = useState([]);
  
    // called by WeekInput to set the week
    function addWeek(week) {
      setWeeks((prevWeeks) => {
        return [...prevWeeks, week];
      });
    }
  
    return (
      <div className="time">
        <Heading heading="Time Card Calculator" />
        <WeekInput onAdd={addWeek} />
        {/* map array of dates for now */}
        {/* change later? */}
        {weeks.map((weekItem, index) => (
          <ReportHeading key={index} id={index} range={weekItem.range} />
        ))}
  
        <table className="table-time">
          <TableHeading />
          {/* iterate to Report table */}
          {weeks.map((weekItem, index) => (
            <Report
              key={index}
              id={index}
              weekTotal={weekItem.weekTotal}
              employee={weekItem.employee}
              otHours={weekItem.otHours}
              twoWeekTotal={weekItem.twoWeekTotal}
              sickHours={weekItem.sickHours}
              // onDelete={deleteNote}
            />
          ))}
        </table>
  
        <PrintReport />
      </div>
    );
  }

export default Time;