import React from "react";
import Heading from "./Heading";
import Footer from "./Footer";
import { NavLink } from "react-router-dom";

function About() {
  return (
    <>
      <div className="App">
        <Heading heading="About Me" />
        <p>I am a front-end developer of websites and web applications. </p>
        <p>
          I graduated cum laude from California State University Northridge
          where I earned a B.S. in Computer Science.
        </p>
        <div>
          <img className="diploma" src="./images/diploma.png" alt="diploma" />
        </div>
        <h2>Skills and Tools:</h2>
        <p>
          HTML5, CSS3, JavaScript, React, Git, Github, REST, Node, Terminal, VS
          Code, Responsive Design
        </p>
        <h2>Currently working on:</h2>
        <p>
          Learning something new every day. Building upon my experience with
          JavaScript, HTML, CSS and other skills and tools.
        </p>
        <p>
          A clone of the popular word game&nbsp;
          <NavLink className="nav-link" to="/game">
            Wordle&nbsp;
          </NavLink>
          and a web based&nbsp;
          <NavLink className="nav-link" to="/time">
            Time Card Calculator
          </NavLink>
          .
        </p>
      </div>
      <Footer />
    </>
  );
}

export default About;
