import React from "react";
import Footer from "./Footer"
// import Heading from "./Heading";

function Home() {

  return (
    <>
      <div className="main">
      <h1 className="h1-home">Hello, My name is <h1 className="h1-name">Stanley Leem.</h1></h1>
      <p>I am a Front End Developer based in Los Angeles, CA.</p>
      <p> </p>
      </div>
      <Footer />
    </>
  );
}

export default Home;
